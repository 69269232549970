import "antd/lib/space/style";
import _Space from "antd/lib/space";
import "antd/lib/tooltip/style";
import _Tooltip from "antd/lib/tooltip";
import "antd/lib/button/style";
import _Button from "antd/lib/button";
import "antd/lib/input/style";
import _Input from "antd/lib/input";
import "antd/lib/typography/style";
import _Typography from "antd/lib/typography";
var __jsx = React.createElement;
import React, { useState, useEffect, useRef } from 'react';
import { EditOutlined, DeleteOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import integrationTestConstants from 'utils/integrationTestConstants';
var Text = _Typography.Text;
var EditableField = function EditableField(props) {
  var value = props.value,
    deleteEnabled = props.deleteEnabled,
    showEdit = props.showEdit,
    onAfterSubmit = props.onAfterSubmit,
    onAfterCancel = props.onAfterCancel,
    renderBold = props.renderBold,
    defaultEditing = props.defaultEditing,
    validationFunc = props.validationFunc,
    onEditing = props.onEditing,
    formatter = props.formatter,
    onDelete = props.onDelete;
  var _useState = useState(defaultEditing),
    editing = _useState[0],
    setEditing = _useState[1];
  var _useState2 = useState(value),
    editedValue = _useState2[0],
    setEditedValue = _useState2[1];
  var _useState3 = useState(true),
    isValid = _useState3[0],
    setIsValid = _useState3[1];
  var saveButton = useRef(null);
  var editButton = useRef(null);
  useEffect(function () {
    setEditedValue(value);
  }, [value]);
  useEffect(function () {
    if (!onEditing) return;
    onEditing(editing);
  }, [editing]);
  var deleteEditableField = function deleteEditableField(e) {
    e.stopPropagation();
    onDelete(e, editedValue);
  };
  var onKeyDown = function onKeyDown(e) {
    if (e.key === 'Enter') {
      onSubmit(e);
    }
    if (e.key === 'Escape') {
      onCancel(e);
    }
  };
  var onChange = function onChange(e) {
    var newValueRaw = e.target.value;
    var newValue = formatter(newValueRaw);
    if (validationFunc) {
      var _valid = value === newValue || validationFunc(newValue);

      // Validation func may not return false on invalid
      setIsValid(_valid === true);
    }
    setEditedValue(newValue);
  };
  var onSubmit = function onSubmit(e) {
    e.stopPropagation();
    if (!isValid) return null;
    onAfterSubmit(editedValue);
    toggleEditing(e);
  };
  var onCancel = function onCancel(e) {
    e.stopPropagation();
    if (!isValid) setIsValid(true);
    setEditedValue(value);
    toggleEditing(e);
    onAfterCancel();
  };
  var toggleEditing = function toggleEditing(e) {
    e.stopPropagation();
    setEditing(!editing);
  };
  var renderEditState = function renderEditState() {
    if (editing) {
      return __jsx(React.Fragment, null, __jsx(_Input, {
        "data-testid": "editableFieldInput",
        "aria-label": "Input",
        autoFocus: true,
        onChange: onChange,
        onClick: function onClick(e) {
          return e.stopPropagation();
        },
        size: "small",
        draggable: true,
        defaultValue: editedValue,
        onKeyDown: onKeyDown
      }), __jsx(_Tooltip, {
        placement: "top",
        title: "Save",
        mouseLeaveDelay: 0,
        ref: saveButton
      }, __jsx(_Button, {
        "aria-label": "Save",
        size: "small",
        shape: "circle",
        icon: __jsx(CheckOutlined, null),
        onClick: function onClick(e) {
          saveButton.current.onMouseLeave();
          onSubmit(e);
        }
      })), __jsx(_Tooltip, {
        placement: "top",
        title: "Cancel",
        mouseLeaveDelay: 0
      }, __jsx(_Button, {
        "aria-label": "Cancel",
        size: "small",
        shape: "circle",
        icon: __jsx(CloseOutlined, null),
        onClick: onCancel
      })));
    }
    return __jsx(React.Fragment, null, renderBold ? __jsx("strong", null, value) : __jsx("span", null, value), showEdit ? __jsx(_Tooltip, {
      placement: "top",
      title: "Edit",
      mouseLeaveDelay: 0,
      ref: editButton
    }, __jsx(_Button, {
      "aria-label": "Edit",
      size: "small",
      shape: "circle",
      icon: __jsx(EditOutlined, null),
      onClick: function onClick(e) {
        editButton.current.onMouseLeave();
        toggleEditing(e);
      }
    })) : __jsx(React.Fragment, null));
  };
  return __jsx(React.Fragment, null, __jsx(_Space, {
    direction: "vertical"
  }, __jsx(_Space, {
    align: "start"
  }, renderEditState(), deleteEnabled ? __jsx(_Tooltip, {
    placement: "top",
    title: "Delete",
    mouseLeaveDelay: 0
  }, __jsx(_Button, {
    "data-test-class": integrationTestConstants.classes.EDITABLE_FIELD_DELETE_BUTTON,
    "aria-label": "Delete",
    size: "small",
    shape: "circle",
    icon: __jsx(DeleteOutlined, null),
    onClick: deleteEditableField
  })) : __jsx(React.Fragment, null)), !isValid ? __jsx(Text, {
    type: "danger",
    style: {
      fontSize: 12,
      fontWeight: 600
    }
  }, validationFunc(editedValue) === false ? 'Invalid input' : validationFunc(editedValue)) : __jsx(React.Fragment, null)));
};
EditableField.defaultProps = {
  onAfterSubmit: function onAfterSubmit() {
    return null;
  },
  onAfterCancel: function onAfterCancel() {
    return null;
  },
  onDelete: function onDelete() {
    return null;
  },
  onEditing: undefined,
  validationFunc: undefined,
  renderBold: false,
  value: null,
  showEdit: true,
  deleteEnabled: true,
  defaultEditing: false,
  formatter: function formatter(value) {
    return value;
  }
};
export default EditableField;