import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import "antd/lib/space/style";
import _Space from "antd/lib/space";
import "antd/lib/tooltip/style";
import _Tooltip from "antd/lib/tooltip";
import "antd/lib/button/style";
import _Button from "antd/lib/button";
import "antd/lib/progress/style";
import _Progress from "antd/lib/progress";
import _defineProperty from "/home/runner/work/ui/ui/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import "antd/lib/typography/style";
import _Typography from "antd/lib/typography";
var __jsx = React.createElement;
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import "core-js/modules/es6.function.name.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import React, { useEffect, useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { deleteSamples, updateSample } from 'redux/actions/samples';
import integrationTestConstants from 'utils/integrationTestConstants';
import UploadStatus, { messageForStatus } from 'utils/upload/UploadStatus';
import styles from 'components/data-management/SamplesTableCells.module.css';
import downloadSampleFile from 'utils/data-management/downloadSampleFile';
import { createAndUploadSampleFile } from 'utils/upload/processSampleUpload';
import { fileTypeToDisplay } from 'utils/sampleFileType';
import EditableField from '../EditableField';
import UploadDetailsModal from './UploadDetailsModal';
var Text = _Typography.Text;
var UploadDivStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  verticalAlign: 'middle'
};
var UploadCell = function UploadCell(props) {
  var _uploadDetailsModalDa;
  var columnId = props.columnId,
    sampleUuid = props.sampleUuid;
  var dispatch = useDispatch();
  var sample = useSelector(function (state) {
    return state.samples[sampleUuid];
  });
  var file = sample === null || sample === void 0 ? void 0 : sample.files[columnId];
  var selectedTech = sample === null || sample === void 0 ? void 0 : sample.type;
  var _useSelector = useSelector(function (state) {
      return state.experiments.meta;
    }),
    activeExperimentId = _useSelector.activeExperimentId;
  var _useState = useState(false),
    uploadDetailsModalVisible = _useState[0],
    setUploadDetailsModalVisible = _useState[1];
  var _useState2 = useState(null),
    uploadDetailsModalData = _useState2[0],
    setUploadDetailsModalData = _useState2[1];
  useEffect(function () {
    setUploadDetailsModalData(file);
  }, [file, file === null || file === void 0 ? void 0 : file.upload]);
  var _ref = (_uploadDetailsModalDa = uploadDetailsModalData === null || uploadDetailsModalData === void 0 ? void 0 : uploadDetailsModalData.upload) !== null && _uploadDetailsModalDa !== void 0 ? _uploadDetailsModalDa : {
      status: UploadStatus.FILE_NOT_FOUND
    },
    _ref$progress = _ref.progress,
    progress = _ref$progress === void 0 ? null : _ref$progress,
    _ref$status = _ref.status,
    status = _ref$status === void 0 ? null : _ref$status;
  var showDetails = function showDetails() {
    setUploadDetailsModalData(_objectSpread({
      sampleUuid: sampleUuid,
      fileCategory: columnId,
      lastModified: sample.lastModified
    }, uploadDetailsModalData));
    setUploadDetailsModalVisible(true);
  };
  var render = function render() {
    if (status === UploadStatus.UPLOADED) {
      return __jsx("div", {
        className: styles.hoverSelectCursor,
        onClick: showDetails,
        onKeyDown: showDetails,
        style: _objectSpread(_objectSpread({}, UploadDivStyle), {}, {
          flexDirection: 'column'
        })
      }, __jsx(Text, {
        type: "success"
      }, messageForStatus(status)));
    }
    if ([UploadStatus.UPLOADING, UploadStatus.COMPRESSING].includes(status)) {
      return __jsx("div", {
        style: _objectSpread(_objectSpread({}, UploadDivStyle), {}, {
          flexDirection: 'column'
        })
      }, __jsx(Text, {
        type: "warning"
      }, "".concat(messageForStatus(status))), progress ? __jsx(_Progress, {
        style: {
          marginLeft: '10%',
          width: '50%'
        },
        percent: progress,
        size: "small"
      }) : __jsx("div", null));
    }
    if (status === UploadStatus.UPLOAD_ERROR) {
      return __jsx("div", {
        className: styles.hoverSelectCursor,
        style: _objectSpread(_objectSpread({}, UploadDivStyle), {}, {
          flexDirection: 'column'
        }),
        onClick: showDetails,
        onKeyDown: showDetails
      }, __jsx(Text, {
        type: "danger"
      }, messageForStatus(status)));
    }
    if ([UploadStatus.FILE_NOT_FOUND, UploadStatus.FILE_READ_ABORTED, UploadStatus.FILE_READ_ERROR].includes(status)) {
      return __jsx("div", {
        style: UploadDivStyle
      }, __jsx(Text, {
        type: "danger"
      }, messageForStatus(status)), __jsx(_Tooltip, {
        placement: "bottom",
        title: "Upload missing",
        mouseLeaveDelay: 0
      }, __jsx(_Button, {
        size: "large",
        shape: "link",
        icon: __jsx(UploadOutlined, null),
        onClick: showDetails
      })));
    }
  };
  var onDownload = function onDownload() {
    downloadSampleFile(activeExperimentId, sampleUuid, uploadDetailsModalData.fileCategory);
  };
  var _onRetry = function onRetry() {
    var fileType = uploadDetailsModalData.fileCategory;

    // if retrying an upload we dont need to revalidate the file since it was done before
    createAndUploadSampleFile(file, fileType, activeExperimentId, sampleUuid, dispatch, selectedTech);
    setUploadDetailsModalVisible(false);
  };
  return __jsx(React.Fragment, null, __jsx("center", null, render()), uploadDetailsModalVisible && __jsx(UploadDetailsModal, {
    data: uploadDetailsModalData,
    onCancel: function onCancel() {
      return setUploadDetailsModalVisible(false);
    },
    onDownload: onDownload,
    onDelete: function onDelete() {
      return dispatch(deleteSamples([sampleUuid]));
    },
    onRetry: function onRetry() {
      return _onRetry();
    },
    extraFields: {
      Sample: sample === null || sample === void 0 ? void 0 : sample.name,
      Category: fileTypeToDisplay[uploadDetailsModalData.fileCategory]
    }
  }));
};
var EditableFieldCell = function EditableFieldCell(props) {
  var sampleUuid = props.sampleUuid,
    trackKey = props.dataIndex,
    rowIdx = props.rowIdx,
    _onAfterSubmit = props.onAfterSubmit;
  var value = useSelector(function (state) {
    var _state$samples$sample;
    return (_state$samples$sample = state.samples[sampleUuid]) === null || _state$samples$sample === void 0 ? void 0 : _state$samples$sample.metadata[trackKey];
  });
  return __jsx("div", {
    key: "cell-".concat(trackKey, "-").concat(rowIdx),
    style: {
      whiteSpace: 'nowrap'
    }
  }, __jsx(_Space, null, __jsx(EditableField, {
    deleteEnabled: false,
    value: value,
    onAfterSubmit: function onAfterSubmit(newValue) {
      return _onAfterSubmit(newValue);
    },
    formatter: function formatter(rawValue) {
      return rawValue.trim();
    }
  })));
};
var SampleNameCell = function SampleNameCell(props) {
  var cellInfo = props.cellInfo;
  var sampleId = cellInfo.record.uuid,
    idx = cellInfo.idx;
  var name = useSelector(function (state) {
    var _state$samples$sample2;
    return (_state$samples$sample2 = state.samples[sampleId]) === null || _state$samples$sample2 === void 0 ? void 0 : _state$samples$sample2.name;
  });
  var dispatch = useDispatch();
  return __jsx(Text, {
    className: integrationTestConstants.classes.SAMPLES_TABLE_NAME_CELL,
    strong: true,
    key: "sample-cell-".concat(idx)
  }, __jsx(EditableField, {
    deleteEnabled: true,
    value: name,
    onAfterSubmit: function onAfterSubmit(newName) {
      return dispatch(updateSample(sampleId, {
        name: newName
      }));
    },
    onDelete: function onDelete() {
      return dispatch(deleteSamples([sampleId]));
    }
  }));
};
export { UploadCell, EditableFieldCell, SampleNameCell };