var __jsx = React.createElement;
import React, { useState } from 'react';
var Expandable = function Expandable(props) {
  var expandedContent = props.expandedContent,
    collapsedContent = props.collapsedContent;
  var _useState = useState(false),
    isExpanded = _useState[0],
    setIsExpanded = _useState[1];
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    __jsx("span", {
      onClick: function onClick() {
        if (!isExpanded) {
          setIsExpanded(true);
        }
      }
    }, !isExpanded ? collapsedContent : __jsx(React.Fragment, null, expandedContent))
  );
};
Expandable.defaultProps = {};
export default Expandable;