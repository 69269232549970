import _defineProperty from "/home/runner/work/ui/ui/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _slicedToArray from "/home/runner/work/ui/ui/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import _has from "lodash/has";
import _get from "lodash/get";
import _mapValues from "lodash/mapValues";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.function.name.js";
import "core-js/modules/es7.object.values.js";
import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import * as INI from 'ini';
import { qcSteps } from '../qcSteps';
var exportQCParameters = function exportQCParameters(config) {
  var sortedConfig = qcSteps.map(function (s, i) {
    var _config$s;
    return ["".concat(i + 1, "-").concat(s), (_config$s = config[s]) !== null && _config$s !== void 0 ? _config$s : {}];
  }).sort();
  var string = INI.stringify(Object.fromEntries(sortedConfig), {
    whitespace: true
  });
  return new Blob([string], {
    type: 'text/plain;charset=utf-8'
  });
};
var filterQCParameters = function filterQCParameters(config, projectSamples, samplesStore) {
  var filtered = _mapValues(config, function (step) {
    return !_get(step, 'enabled', true) ? {
      disabled: true
    } : Object.fromEntries(projectSamples.map(function (sample) {
      var _samplesStore$sample, _step$sample$filterSe, _step$sample;
      return [(_samplesStore$sample = samplesStore[sample]) === null || _samplesStore$sample === void 0 ? void 0 : _samplesStore$sample.name, flattenSampleStepConfig((_step$sample$filterSe = (_step$sample = step[sample]) === null || _step$sample === void 0 ? void 0 : _step$sample.filterSettings) !== null && _step$sample$filterSe !== void 0 ? _step$sample$filterSe : _mapValues(step, flattenSampleStepConfig))];
    }));
  });

  // settings for these steps are the same for all samples, so we can remove
  // duplication
  var _Object$values = Object.values(filtered.dataIntegration);
  var _Object$values2 = _slicedToArray(_Object$values, 1);
  filtered.dataIntegration = _Object$values2[0];
  var _Object$values3 = Object.values(filtered.configureEmbedding);
  var _Object$values4 = _slicedToArray(_Object$values3, 1);
  filtered.configureEmbedding = _Object$values4[0];
  return filtered;
};
var flattenSampleStepConfig = function flattenSampleStepConfig(stepConfig) {
  // for steps with multiple methods to choose from, only include
  // configuration for the method that is actually selected
  if (_has(stepConfig, 'methodSettings')) {
    return _objectSpread({
      method: stepConfig.method
    }, stepConfig.methodSettings[stepConfig.method]);
  }
  if (_has(stepConfig, 'regressionTypeSettings')) {
    // numGenesVsNumUmis
    return _objectSpread({
      regressionType: stepConfig.regressionType
    }, stepConfig.regressionTypeSettings[stepConfig.regressionType]);
  }
  return stepConfig;
};
export { exportQCParameters, filterQCParameters };