import "antd/lib/card/style";
import _Card from "antd/lib/card";
import "antd/lib/descriptions/style";
import _Descriptions from "antd/lib/descriptions";
var __jsx = React.createElement;
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.function.name.js";
import React, { useState } from 'react';
import { blue } from '@ant-design/colors';
import { useSelector, useDispatch } from 'react-redux';
import { updateExperiment, setActiveExperiment } from 'redux/actions/experiments';
import validateInputs, { rules } from 'utils/validateInputs';
import integrationTestConstants from 'utils/integrationTestConstants';
import EditableField from 'components/EditableField';
import PrettyTime from 'components/PrettyTime';
import ProjectDeleteModal from 'components/data-management/project/ProjectDeleteModal';
var Item = _Descriptions.Item;
var validationChecks = [rules.MIN_8_CHARS, rules.MIN_2_SEQUENTIAL_CHARS, rules.ALPHANUM_DASH_SPACE, rules.UNIQUE_NAME_CASE_INSENSITIVE];
var inactiveExperimentStyle = {
  cursor: 'pointer'
};
var activeExperimentStyle = {
  backgroundColor: blue[0],
  cursor: 'pointer',
  border: "2px solid ".concat(blue.primary)
};
var itemTextStyle = {
  fontWeight: 'bold'
};
var ProjectCard = function ProjectCard(props) {
  var experimentId = props.experimentId;
  var dispatch = useDispatch();
  var experiments = useSelector(function (state) {
    return state.experiments;
  });
  var _useState = useState(false),
    deleteModalVisible = _useState[0],
    setDeleteModalVisible = _useState[1];
  var activeExperimentId = experiments.meta.activeExperimentId;
  var experimentCardStyle = activeExperimentId === experimentId ? activeExperimentStyle : inactiveExperimentStyle;
  var experiment = experiments[experimentId];
  var experimentNames = experiments.ids.map(function (id) {
    return experiments[id].name;
  });
  var validationParams = {
    existingNames: experimentNames
  };
  var updateExperimentName = function updateExperimentName(newName) {
    dispatch(updateExperiment(experiment.id, {
      name: newName.trim()
    }));
  };
  var deleteExperiment = function deleteExperiment() {
    setDeleteModalVisible(true);
  };
  return __jsx(React.Fragment, null, deleteModalVisible && __jsx(ProjectDeleteModal, {
    key: "".concat(experiment.id, "-name"),
    experimentId: experiment.id,
    onCancel: function onCancel() {
      setDeleteModalVisible(false);
    },
    onDelete: function onDelete() {
      setDeleteModalVisible(false);
    }
  }), __jsx(_Card, {
    "data-test-class": integrationTestConstants.classes.PROJECT_CARD,
    key: experimentId,
    type: "primary",
    style: experimentCardStyle,
    onClick: function onClick() {
      dispatch(setActiveExperiment(experiment.id));
    }
  }, __jsx(_Descriptions, {
    layout: "horizontal",
    size: "small",
    column: 1
  }, __jsx(Item, {
    contentStyle: {
      fontWeight: 700,
      fontSize: 16
    }
  }, __jsx(EditableField, {
    value: experiment.name,
    onAfterSubmit: updateExperimentName,
    onDelete: deleteExperiment,
    validationFunc: function validationFunc(newName) {
      return validateInputs(newName, validationChecks, validationParams).isValid;
    }
  })), __jsx(Item, {
    labelStyle: itemTextStyle,
    label: "Samples"
  }, experiment.sampleIds.length), __jsx(Item, {
    labelStyle: itemTextStyle,
    label: "Created"
  }, __jsx(PrettyTime, {
    isoTime: experiment.createdAt
  })), __jsx(Item, {
    labelStyle: itemTextStyle,
    label: "Modified"
  }, __jsx(PrettyTime, {
    isoTime: experiment.updatedAt
  })))));
};
export default ProjectCard;