import "antd/lib/space/style";
import _Space from "antd/lib/space";
import "antd/lib/input/style";
import _Input from "antd/lib/input";
var __jsx = React.createElement;
import "core-js/modules/es6.function.name.js";
import React from 'react';
import { useDispatch } from 'react-redux';
import { renameMetadataTrack } from 'redux/actions/experiments';
import validateInputs, { rules } from 'utils/validateInputs';
import { metadataNameToKey } from 'utils/data-management/metadataUtils';
import { METADATA_DEFAULT_VALUE } from 'redux/reducers/experiments/initialState';
import EditableField from '../../EditableField';
import MetadataEditor from './MetadataEditor';
var MetadataColumnTitle = function MetadataColumnTitle(props) {
  var name = props.name,
    sampleNames = props.sampleNames,
    activeExperimentId = props.activeExperimentId,
    deleteMetadataColumn = props.deleteMetadataColumn,
    setCells = props.setCells;
  var validationParams = {
    existingNames: sampleNames
  };
  return __jsx(MetadataTitle, {
    name: name,
    validateInput: function validateInput(newName, metadataNameValidation) {
      return validateInputs(newName, metadataNameValidation, validationParams).isValid;
    },
    setCells: setCells,
    deleteMetadataColumn: deleteMetadataColumn,
    activeExperimentId: activeExperimentId
  });
};
var MetadataTitle = function MetadataTitle(props) {
  var dispatch = useDispatch();
  var name = props.name,
    validateInput = props.validateInput,
    setCells = props.setCells,
    deleteMetadataColumn = props.deleteMetadataColumn,
    activeExperimentId = props.activeExperimentId;
  var metaKey = metadataNameToKey(name);
  var metadataNameValidation = [rules.MIN_1_CHAR, rules.ALPHANUM_SPACE, rules.START_WITH_ALPHABET, rules.UNIQUE_NAME_CASE_INSENSITIVE];
  return __jsx(_Space, null, __jsx(EditableField, {
    deleteEnabled: true,
    onDelete: function onDelete(e, currentName) {
      return deleteMetadataColumn(currentName);
    },
    onAfterSubmit: function onAfterSubmit(newName) {
      return dispatch(renameMetadataTrack(name, newName, activeExperimentId));
    },
    value: name,
    validationFunc: function validationFunc(newName) {
      return validateInput(newName, metadataNameValidation);
    },
    formatter: function formatter(value) {
      return value.trim();
    }
  }), __jsx(MetadataEditor, {
    onReplaceEmpty: function onReplaceEmpty(value) {
      return setCells(value, metaKey, 'REPLACE_EMPTY');
    },
    onReplaceAll: function onReplaceAll(value) {
      return setCells(value, metaKey, 'REPLACE_ALL');
    },
    onClearAll: function onClearAll() {
      return setCells(METADATA_DEFAULT_VALUE, metaKey, 'CLEAR_ALL');
    },
    massEdit: true
  }, __jsx(_Input, null)));
};
export default MetadataColumnTitle;