import _defineProperty from "/home/runner/work/ui/ui/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
var __jsx = React.createElement;
import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadExperiments } from 'redux/actions/experiments';
import Header from 'components/Header';
import MultiTileContainer from 'components/MultiTileContainer';
import NewProjectModal from 'components/data-management/NewProjectModal';
import ProjectsListContainer from 'components/data-management/project/ProjectsListContainer';
import ProjectDetails from 'components/data-management/project/ProjectDetails';
import { loadProcessingSettings } from 'redux/actions/experimentSettings';
import loadBackendStatus from 'redux/actions/backendStatus/loadBackendStatus';
import { loadSamples } from 'redux/actions/samples';
import ExampleExperimentsSpace from 'components/data-management/ExampleExperimentsSpace';
import Loader from 'components/Loader';
import { privacyPolicyIsNotAccepted } from 'utils/deploymentInfo';
var DataManagementPage = function DataManagementPage() {
  var dispatch = useDispatch();
  var samples = useSelector(function (state) {
    return state.samples;
  });
  var _useSelector = useSelector(function (state) {
      return state.experiments.meta;
    }),
    activeExperimentId = _useSelector.activeExperimentId;
  var experiments = useSelector(function (state) {
    return state.experiments;
  });
  var user = useSelector(function (state) {
    return state.user.current;
  });
  var activeExperiment = experiments[activeExperimentId];
  var domainName = useSelector(function (state) {
    var _state$networkResourc;
    return (_state$networkResourc = state.networkResources) === null || _state$networkResourc === void 0 ? void 0 : _state$networkResourc.domainName;
  });
  var _useState = useState(false),
    newProjectModalVisible = _useState[0],
    setNewProjectModalVisible = _useState[1];
  useEffect(function () {
    if (privacyPolicyIsNotAccepted(user, domainName)) return;
    if (experiments.ids.length === 0) dispatch(loadExperiments());
  }, [user]);
  var samplesAreLoaded = function samplesAreLoaded() {
    var loadedSampleIds = Object.keys(samples);
    return activeExperiment.sampleIds.every(function (sampleId) {
      return loadedSampleIds.includes(sampleId);
    });
  };
  useEffect(function () {
    // If the active experiment isnt loaded, reload
    if (activeExperimentId && !activeExperiment) {
      dispatch(loadExperiments());
    }
  }, [activeExperiment]);
  useEffect(function () {
    if (!activeExperimentId || !activeExperiment || privacyPolicyIsNotAccepted(user, domainName)) return;
    dispatch(loadProcessingSettings(activeExperimentId));
    if (!samplesAreLoaded()) dispatch(loadSamples(activeExperimentId));
    dispatch(loadBackendStatus(activeExperimentId));
  }, [activeExperimentId, activeExperiment, user]);
  var PROJECTS_LIST = 'Projects';
  var PROJECT_DETAILS = 'Project Details';
  var TILE_MAP = _defineProperty(_defineProperty({}, PROJECTS_LIST, {
    toolbarControls: [],
    component: function component(width, height) {
      return __jsx(ProjectsListContainer, {
        height: height,
        onCreateNewProject: function onCreateNewProject() {
          return setNewProjectModalVisible(true);
        }
      });
    }
  }), PROJECT_DETAILS, {
    toolbarControls: [],
    component: function component(width, height) {
      if (!activeExperimentId) {
        return __jsx(ExampleExperimentsSpace, {
          introductionText: "You have no projects yet."
        });
      }
      if (!activeExperiment) {
        return __jsx("center", null, __jsx(Loader, null));
      }
      return __jsx(ProjectDetails, {
        width: width,
        height: height
      });
    }
  });
  var windows = {
    direction: 'row',
    first: PROJECTS_LIST,
    second: PROJECT_DETAILS,
    splitPercentage: 23
  };
  return __jsx(React.Fragment, null, __jsx(Header, {
    title: "Data Management"
  }), newProjectModalVisible ? __jsx(NewProjectModal, {
    onCancel: function onCancel() {
      setNewProjectModalVisible(false);
    },
    onCreate: function onCreate() {
      setNewProjectModalVisible(false);
    }
  }) : __jsx(React.Fragment, null), __jsx(MultiTileContainer, {
    tileMap: TILE_MAP,
    initialArrangement: windows
  }));
};
export default DataManagementPage;