import _defineProperty from "/home/runner/work/ui/ui/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import "antd/lib/checkbox/style";
import _Checkbox from "antd/lib/checkbox";
import "antd/lib/tooltip/style";
import _Tooltip from "antd/lib/tooltip";
import "antd/lib/typography/style";
import _Typography from "antd/lib/typography";
var __jsx = React.createElement;
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { QuestionCircleOutlined } from '@ant-design/icons';
import updateSamplesOptions from 'redux/actions/experiments/updateSamplesOptions';
import { sampleTech } from 'utils/constants';
var Text = _Typography.Text,
  Paragraph = _Typography.Paragraph;
var SamplesOptions = function SamplesOptions() {
  var dispatch = useDispatch();
  var activeExperimentId = useSelector(function (state) {
    return state.experiments.meta.activeExperimentId;
  });

  // Before sample-specific options are supported, we assume that the tech and options values
  // of the first sample applies for all samples in the experiment
  var getExperimentWideValue = function getExperimentWideValue(experimentId) {
    if (!experimentId) return {};
    var activeExperiment = useSelector(function (state) {
      return state.experiments[experimentId];
    });
    var firstSampleId = activeExperiment === null || activeExperiment === void 0 ? void 0 : activeExperiment.sampleIds[0];
    var _useSelector = useSelector(function (state) {
        return state.samples[firstSampleId] || {};
      }),
      selectedTech = _useSelector.type,
      sampleOptions = _useSelector.options;
    return {
      selectedTech: selectedTech,
      sampleOptions: sampleOptions
    };
  };
  var _getExperimentWideVal = getExperimentWideValue(activeExperimentId),
    selectedTech = _getExperimentWideVal.selectedTech,
    sampleOptions = _getExperimentWideVal.sampleOptions;
  var updateAllSamples = function updateAllSamples(diff) {
    dispatch(updateSamplesOptions(activeExperimentId, diff));
  };
  var renderRhapsodyOption = function renderRhapsodyOption() {
    return __jsx(React.Fragment, null, __jsx(Paragraph, null, __jsx(_Checkbox, {
      checked: sampleOptions === null || sampleOptions === void 0 ? void 0 : sampleOptions.includeAbSeq,
      onChange: function onChange(e) {
        return updateAllSamples({
          includeAbSeq: e.target.checked
        });
      }
    }, "Include AbSeq data", ' ', __jsx(_Tooltip, {
      overlayStyle: {
        minWidth: '400px'
      },
      title: "AbSeq data is filtered out by default. Checking this box includes the AbSeq data. Support for AbSeq is currently for visualization purposes only, as experiment-wide normalization will be slightly skewed. In case there is AbSeq data in your experiment, we suggest you create two projects; one including AbSeq data and one without, and compare the results."
    }, __jsx(QuestionCircleOutlined, null)))));
  };
  var renderOptions = _defineProperty({}, sampleTech.RHAPSODY, renderRhapsodyOption);
  return renderOptions[selectedTech] ? __jsx(React.Fragment, null, __jsx(Text, {
    strong: true
  }, "Options"), renderOptions[selectedTech]()) : null;
};
export default SamplesOptions;