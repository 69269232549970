import "antd/lib/empty/style";
import _Empty from "antd/lib/empty";
import "antd/lib/space/style";
import _Space from "antd/lib/space";
import "antd/lib/button/style";
import _Button from "antd/lib/button";
import "antd/lib/typography/style";
import _Typography from "antd/lib/typography";
var __jsx = React.createElement;
import React from 'react';
import { useAppRouter } from 'utils/AppRouteProvider';
import { modules } from 'utils/constants';
var Paragraph = _Typography.Paragraph;
var ExampleExperimentsSpace = function ExampleExperimentsSpace(_ref) {
  var introductionText = _ref.introductionText,
    imageStyle = _ref.imageStyle;
  var _useAppRouter = useAppRouter(),
    navigateTo = _useAppRouter.navigateTo;
  return __jsx(_Empty, {
    imageStyle: imageStyle,
    description: __jsx(_Space, {
      size: "middle",
      direction: "vertical"
    }, __jsx(Paragraph, null, introductionText), __jsx(_Button, {
      type: "primary",
      block: true,
      onClick: function onClick() {
        navigateTo(modules.REPOSITORY);
      }
    }, "Don't have data? Get started using one of our example datasets!"))
  });
};
ExampleExperimentsSpace.defaultProps = {
  introductionText: '',
  imageStyle: {}
};
export default ExampleExperimentsSpace;