import "antd/lib/modal/style";
import _Modal from "antd/lib/modal";
import "antd/lib/form/style";
import _Form from "antd/lib/form";
import "antd/lib/input/style";
import _Input from "antd/lib/input";
import "antd/lib/alert/style";
import _Alert from "antd/lib/alert";
import "antd/lib/space/style";
import _Space from "antd/lib/space";
import "antd/lib/button/style";
import _Button from "antd/lib/button";
import "antd/lib/typography/style";
import _Typography from "antd/lib/typography";
var __jsx = React.createElement;
import "core-js/modules/es6.function.name.js";
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteExperiment } from 'redux/actions/experiments';
import integrationTestConstants from 'utils/integrationTestConstants';
var Text = _Typography.Text,
  Paragraph = _Typography.Paragraph;
var ProjectDeleteModal = function ProjectDeleteModal(props) {
  var experimentId = props.experimentId,
    onCancel = props.onCancel,
    onDelete = props.onDelete;
  var dispatch = useDispatch();
  var experimentName = useSelector(function (state) {
    return state.experiments[experimentId].name;
  });
  var _useState = useState(''),
    inputExperimentName = _useState[0],
    setInputExperimentName = _useState[1];
  var _useState2 = useState(false),
    isValid = _useState2[0],
    setIsValid = _useState2[1];
  return __jsx(_Modal, {
    className: integrationTestConstants.classes.DELETE_PROJECT_MODAL,
    title: "Confirm delete",
    open: true,
    footer: __jsx(_Space, null, __jsx(_Button, {
      type: "secondary",
      key: "cancel",
      onClick: function onClick() {
        onCancel();
        setIsValid(false);
      }
    }, "Keep project"), __jsx(_Button, {
      type: "danger",
      key: "create",
      disabled: !isValid,
      onClick: function onClick() {
        dispatch(deleteExperiment(experimentId));
        onDelete();
      }
    }, "Permanently delete project")),
    onCancel: onCancel
  }, __jsx(_Space, null, __jsx(_Space, {
    direction: "vertical"
  }, __jsx(Paragraph, null, "Are you", ' ', __jsx(Text, {
    strong: true
  }, "absolutely"), ' ', "sure?"), __jsx(Paragraph, null, ' ', "This will delete the project", ' ', __jsx(Text, {
    strong: true
  }, experimentName), ', ', "all of its data sets, metadata, analyses, and all other information under this project."), __jsx(Paragraph, null, __jsx(_Alert, {
    message: "This action cannot be undone. Make sure you understand its effects before continuing.",
    type: "warning",
    showIcon: true
  })), __jsx(_Form, {
    layout: "vertical"
  }, __jsx(_Form.Item, {
    label: "Type in the name of the project to confirm:"
  }, __jsx(_Input, {
    "data-test-id": integrationTestConstants.classes.DELETE_PROJECT_MODAL_INPUT,
    onChange: function onChange(e) {
      setIsValid(experimentName === e.target.value);
      setInputExperimentName(e.target.value);
    },
    placeholder: experimentName,
    value: inputExperimentName
  }))))));
};
ProjectDeleteModal.defaultProps = {
  onCancel: function onCancel() {
    return null;
  },
  onDelete: function onDelete() {
    return null;
  }
};
export default ProjectDeleteModal;