var __jsx = React.createElement;
import React, { useState } from 'react';
import dayjs from 'dayjs';
import relativeTimePlugin from 'dayjs/plugin/relativeTime';
import localizedFormatPlugin from 'dayjs/plugin/localizedFormat';
dayjs.extend(relativeTimePlugin);
dayjs.extend(localizedFormatPlugin);
dayjs.locale('en-US');
var PrettyTime = function PrettyTime(props) {
  var isoTime = props.isoTime;
  var relativeTime = dayjs(isoTime).fromNow();
  var localIsoTime = dayjs(isoTime).format('LLLL');
  var _useState = useState(relativeTime),
    displayedTime = _useState[0],
    setDisplayedTime = _useState[1];
  return __jsx("span", {
    style: {
      textDecoration: 'underline dotted'
    },
    onMouseEnter: function onMouseEnter() {
      return setDisplayedTime("on ".concat(localIsoTime));
    },
    onMouseLeave: function onMouseLeave() {
      return setDisplayedTime(relativeTime);
    }
  }, !isoTime ? 'invalid date' : displayedTime);
};
PrettyTime.defaultProps = {
  isoTime: null
};
export default PrettyTime;