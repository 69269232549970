import "antd/lib/modal/style";
import _Modal from "antd/lib/modal";
import "antd/lib/progress/style";
import _Progress from "antd/lib/progress";
import "antd/lib/row/style";
import _Row from "antd/lib/row";
import "antd/lib/col/style";
import _Col from "antd/lib/col";
import "antd/lib/button/style";
import _Button from "antd/lib/button";
var __jsx = React.createElement;
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.object.keys.js";
/* eslint-disable import/no-duplicates */
import React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import UploadStatus, { messageForStatus } from 'utils/upload/UploadStatus';
dayjs.extend(utc);
var UploadDetailsModal = function UploadDetailsModal(props) {
  var onCancel = props.onCancel,
    data = props.data,
    extraFields = props.extraFields,
    onDownload = props.onDownload,
    onRetry = props.onRetry,
    onDelete = props.onDelete;
  var upload = data.upload,
    size = data.size,
    lastModified = data.lastModified,
    _data$fileObject = data.fileObject,
    fileObject = _data$fileObject === void 0 ? undefined : _data$fileObject;
  var _ref = upload !== null && upload !== void 0 ? upload : {},
    progress = _ref.progress,
    status = _ref.status;
  var isSuccessModal = status === UploadStatus.UPLOADED;
  var isNotUploadedModal = status === UploadStatus.FILE_NOT_FOUND;
  var isUploading = status === UploadStatus.UPLOADING;
  var modalTitle = messageForStatus(status);
  function bytesToSize(bytes) {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return 'n/a';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
    if (i === 0) return "".concat(bytes, " ").concat(sizes[i]);
    return "".concat((bytes / Math.pow(1024, i)).toFixed(1), " ").concat(sizes[i]);
  }
  var fromISODateToFormatted = function fromISODateToFormatted(ISOStringDate) {
    var date = dayjs(ISOStringDate);
    var weekDayName = date.format('dddd');
    var fullDate = date.local().format('DD MMM YYYY');
    var fullTime = date.local().format('HH:mm');
    return "".concat(weekDayName, ", ").concat(fullDate, " at ").concat(fullTime);
  };
  var retryButton = function retryButton() {
    return __jsx(_Button, {
      type: "primary",
      key: "retry",
      disabled: !fileObject,
      block: true,
      onClick: function onClick() {
        onRetry();
      },
      style: {
        width: '140px',
        marginBottom: '10px'
      }
    }, "Retry upload");
  };
  var downloadButton = function downloadButton() {
    return __jsx(_Button, {
      type: "primary",
      key: "retry",
      block: true,
      onClick: function onClick() {
        onDownload();
      },
      style: {
        width: '140px',
        marginBottom: '10px'
      }
    }, "Download");
  };
  var renderFields = function renderFields(fields) {
    return Object.keys(fields).map(function (key) {
      return __jsx(_Row, {
        style: {
          marginTop: '5px',
          marginBottom: '5px'
        }
      }, __jsx(_Col, {
        span: 5
      }, key), __jsx(_Col, {
        span: 10
      }, fields[key]));
    });
  };
  return __jsx(_Modal, {
    title: modalTitle,
    open: true,
    onCancel: onCancel,
    width: "40%",
    footer: __jsx(_Row, {
      style: {
        width: '100%',
        justifyContent: 'center'
      }
    }, __jsx(_Col, null, !isNotUploadedModal && (isSuccessModal ? downloadButton() : retryButton())), __jsx(_Col, {
      span: "2"
    }), __jsx(_Button, {
      danger: true,
      onClick: function onClick() {
        onDelete();
        onCancel();
      },
      style: {
        width: '140px',
        marginBottom: '10px'
      }
    }, "Delete"), __jsx(_Col, null))
  }, __jsx("div", {
    style: {
      width: '100%',
      marginLeft: '15px'
    }
  }, !isSuccessModal && !isUploading && __jsx(_Row, {
    style: {
      marginTop: '5px',
      marginBottom: '5px'
    }
  }, "The following file", ' ', isNotUploadedModal ? 'was not uploaded' : 'has failed to upload'), renderFields(extraFields), isSuccessModal || isUploading ? renderFields({
    'File size': bytesToSize(size),
    'Upload date': fromISODateToFormatted(lastModified)
  }) : renderFields({
    Error: messageForStatus(status)
  }), progress ? renderFields({
    Progress: __jsx(_Progress, {
      style: {
        width: '100%'
      },
      percent: progress,
      size: "small"
    })
  }) : __jsx("div", null)));
};
UploadDetailsModal.defaultProps = {
  extraFields: {}
};
export default UploadDetailsModal;