import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "/home/runner/work/ui/ui/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
var __jsx = React.createElement;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import React from 'react';
import { Mosaic, MosaicWindow } from 'react-mosaic-component';
import ReactResizeDetector from 'react-resize-detector';
import 'react-mosaic-component/react-mosaic-component.css';
import { layout } from 'utils/constants';
import { useDragDropManager } from 'react-dnd';
var renderWindow = function renderWindow(tile, width, height, style) {
  if (!tile) return __jsx(React.Fragment, null);
  return __jsx("div", {
    style: _objectSpread({
      padding: layout.PANEL_PADDING,
      overflow: 'auto'
    }, style)
  }, height && width ? tile(width, height) : __jsx(React.Fragment, null));
};
var MultiTileContainer = function MultiTileContainer(_ref) {
  var tileMap = _ref.tileMap,
    initialArrangement = _ref.initialArrangement;
  var dragDropManager = useDragDropManager();
  return __jsx("div", {
    style: {
      height: '100%',
      width: '100%',
      margin: 0
    }
  }, __jsx(Mosaic, {
    dragAndDropManager: dragDropManager,
    renderTile: function renderTile(id, path) {
      return __jsx(ReactResizeDetector, {
        handleWidth: true,
        handleHeight: true,
        refreshMode: "throttle",
        refreshRate: 500
      }, function (_ref2) {
        var _tileMap$id, _tileMap$id2, _tileMap$id3;
        var width = _ref2.width,
          height = _ref2.height;
        return __jsx(MosaicWindow, {
          path: path,
          title: id,
          toolbarControls: (_tileMap$id = tileMap[id]) === null || _tileMap$id === void 0 ? void 0 : _tileMap$id.toolbarControls
        }, renderWindow((_tileMap$id2 = tileMap[id]) === null || _tileMap$id2 === void 0 ? void 0 : _tileMap$id2.component, width, height, (_tileMap$id3 = tileMap[id]) === null || _tileMap$id3 === void 0 ? void 0 : _tileMap$id3.style));
      });
    },
    initialValue: initialArrangement
  }));
};
export default MultiTileContainer;