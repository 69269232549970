import "antd/lib/popover/style";
import _Popover from "antd/lib/popover";
import _extends from "/home/runner/work/ui/ui/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/home/runner/work/ui/ui/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["existingMetadata", "popoverPosition", "onCreate", "onCancel", "message", "children"];
var __jsx = React.createElement;
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.regexp.match.js";
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { metadataKeyToName, metadataNameToKey } from 'utils/data-management/metadataUtils';
import validateInputs, { rules } from 'utils/validateInputs';
import EditableField from 'components/EditableField';
var validationChecks = [rules.MIN_1_CHAR, rules.ALPHANUM_SPACE, rules.UNIQUE_NAME_CASE_INSENSITIVE, rules.START_WITH_ALPHABET];
var MetadataPopover = function MetadataPopover(props) {
  var existingMetadata = props.existingMetadata,
    popoverPosition = props.popoverPosition,
    onCreate = props.onCreate,
    onCancel = props.onCancel,
    message = props.message,
    children = props.children,
    restOfProps = _objectWithoutProperties(props, _excluded);
  var validationParams = {
    existingNames: existingMetadata.map(function (metadataKey) {
      return metadataKeyToName(metadataKey).toLowerCase();
    })
  };
  var getContent = function getContent() {
    return __jsx(EditableField, {
      onAfterSubmit: function onAfterSubmit(value) {
        onCreate(value);
      },
      onAfterCancel: function onAfterCancel() {
        onCancel();
      },
      deleteEnabled: false,
      value: "Track ".concat(existingMetadata.filter(function (key) {
        return key.match('Track_');
      }).length + 1),
      defaultEditing: true,
      validationFunc: function validationFunc(name) {
        return validateInputs(metadataKeyToName(metadataNameToKey(name)), validationChecks, validationParams).isValid;
      },
      formatter: function formatter(value) {
        return value.trim();
      }
    });
  };
  var content = getContent();
  var style = {};
  if (popoverPosition) {
    style = {
      position: 'absolute',
      left: popoverPosition.current.x + 20,
      top: popoverPosition.current.y + 20
    };
  }
  return __jsx("div", {
    style: style
  }, __jsx(_Popover, _extends({
    title: message,
    content: content
  }, restOfProps, {
    autoAdjustOverflow: true
  }), children));
};
MetadataPopover.defaultProps = {
  popoverPosition: null,
  message: 'Add cell set',
  children: null,
  existingMetadata: []
};
export default MetadataPopover;