import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import "antd/lib/skeleton/style";
import _Skeleton from "antd/lib/skeleton";
import _toConsumableArray from "/home/runner/work/ui/ui/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import "antd/lib/space/style";
import _Space from "antd/lib/space";
import _defineProperty from "/home/runner/work/ui/ui/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
var __jsx = React.createElement;
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.regexp.match.js";
import "core-js/modules/es6.function.name.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import React, { useCallback, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { VariableSizeList as List } from 'react-window';
import integrationTestConstants from 'utils/integrationTestConstants';
import ProjectCard from './ProjectCard';

// This makes sure that all the projects can be viewed properly inside the list
// TODO : This has to be done properly in CSS
var windowMargin = 130; // px

var Row = function Row(_ref) {
  var index = _ref.index,
    data = _ref.data,
    style = _ref.style,
    setSize = _ref.setSize;
  var rowRef = useRef();
  var experiment = data[index];
  useEffect(function () {
    if (rowRef.current) {
      setSize(index, rowRef.current.getBoundingClientRect().height);
    }
  }, [setSize, index, experiment]);
  return __jsx(_Space, {
    style: _objectSpread(_objectSpread({}, style), {}, {
      width: '100%'
    })
  }, __jsx("div", {
    ref: rowRef
  }, __jsx(ProjectCard, {
    key: experiment.id,
    experimentId: experiment.id
  })));
};
var ProjectsList = function ProjectsList(props) {
  var height = props.height,
    filter = props.filter;
  var listRef = useRef();
  var sizeMap = useRef({});
  var experiments = useSelector(function (state) {
    return state.experiments;
  });
  var setSize = useCallback(function (index, size) {
    sizeMap.current[index] = size + 5;
    //  if the height gets changed, we need to reset the heights
    // so that they are recalculated
    listRef.current.resetAfterIndex(index);
  }, []);
  var getSize = function getSize(index) {
    return sizeMap.current[index] || 204;
  }; // default height if not yet measured

  var filteredExperiments = experiments.ids.map(function (id) {
    return experiments[id];
  }).filter(function (exp) {
    return exp.name.match(filter) || exp.id.match(filter);
  });
  if (experiments.meta.loading) {
    return _toConsumableArray(Array(5)).map(function (_, idx) {
      return __jsx(_Skeleton, {
        key: "skeleton-".concat(idx),
        role: "progressbar",
        active: true
      });
    });
  }
  if (filteredExperiments.length === 0) {
    return __jsx("div", {
      "data-test-id": integrationTestConstants.ids.PROJECTS_LIST
    });
  }
  return __jsx("div", {
    "data-test-id": integrationTestConstants.ids.PROJECTS_LIST
  }, __jsx(List, {
    ref: listRef,
    height: height - windowMargin,
    width: "100%",
    itemCount: filteredExperiments.length,
    itemSize: getSize,
    itemData: filteredExperiments
  }, function (_ref2) {
    var data = _ref2.data,
      index = _ref2.index,
      style = _ref2.style;
    return __jsx(Row, {
      data: data,
      index: index,
      style: style,
      setSize: setSize
    });
  }));
};
ProjectsList.defaultProps = {
  height: 800
};
export default ProjectsList;