import _isEqual from "lodash/isEqual";
import React, { useRef } from 'react';

// Custom useEffect hook that runs only when
// a comparator notices a difference between one of the dependencies previous and new values
// By default, the comparator used is _.isEqual
// It can be set as lazy (so that it doesnt run on the first render)
var useConditionalEffect = function useConditionalEffect(callback, dependencies) {
  var optionals = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var _optionals$comparator = optionals.comparator,
    comparator = _optionals$comparator === void 0 ? _isEqual : _optionals$comparator,
    _optionals$lazy = optionals.lazy,
    lazy = _optionals$lazy === void 0 ? false : _optionals$lazy;
  var firstRenderRef = useRef(true);
  var dependenciesRef = useRef(dependencies);
  React.useEffect(function () {
    var somethingChanged = !comparator(dependenciesRef.current, dependencies);
    if (firstRenderRef.current && !lazy || somethingChanged) {
      callback.apply(void 0, arguments);
    }
    dependenciesRef.current = dependencies;
    firstRenderRef.current = false;
  }, dependencies);
};
export default useConditionalEffect;