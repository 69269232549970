import "antd/lib/space/style";
import _Space from "antd/lib/space";
import "antd/lib/dropdown/style";
import _Dropdown from "antd/lib/dropdown";
import "antd/lib/button/style";
import _Button from "antd/lib/button";
var __jsx = React.createElement;
import "core-js/modules/es6.regexp.constructor.js";
import React, { useState } from 'react';
import { useAppRouter } from 'utils/AppRouteProvider';
import integrationTestConstants from 'utils/integrationTestConstants';
import { modules } from 'utils/constants';
import ProjectsList from './ProjectsList';
import ProjectSearchBox from './ProjectSearchBox';
var ProjectsListContainer = function ProjectsListContainer(props) {
  var height = props.height,
    onCreateNewProject = props.onCreateNewProject;
  var _useAppRouter = useAppRouter(),
    navigateTo = _useAppRouter.navigateTo;
  var _useState = useState(new RegExp('.*', 'i')),
    filterParam = _useState[0],
    setFilterParam = _useState[1];
  var menuItems = [{
    label: 'Upload Project',
    key: 'upload_project',
    onClick: function onClick() {
      return onCreateNewProject();
    }
  }, {
    label: 'Select from Dataset Repository',
    key: 'copy_from_repository',
    onClick: function onClick() {
      navigateTo(modules.REPOSITORY);
    }
  }];
  return __jsx(_Space, {
    direction: "vertical",
    style: {
      width: '100%'
    }
  }, __jsx(_Dropdown, {
    menu: {
      items: menuItems
    },
    trigger: ['click'],
    placement: "bottomRight"
  }, __jsx(_Button, {
    "data-test-id": integrationTestConstants.ids.CREATE_NEW_PROJECT_BUTTON,
    type: "primary",
    block: true
  }, "Create New Project")), __jsx(ProjectSearchBox, {
    onChange: function onChange(searchRegex) {
      return setFilterParam(searchRegex);
    }
  }), __jsx(ProjectsList, {
    height: height,
    filter: filterParam
  }));
};
export default ProjectsListContainer;