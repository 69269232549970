import "antd/lib/tooltip/style";
import _Tooltip from "antd/lib/tooltip";
import "antd/lib/input/style";
import _Input from "antd/lib/input";
import _debounce from "lodash/debounce";
var __jsx = React.createElement;
import "core-js/modules/es6.regexp.constructor.js";
import React, { useCallback } from 'react';
var ProjectSearchBox = function ProjectSearchBox(props) {
  var onChange = props.onChange;
  var debouncedSetFilterParam = useCallback(_debounce(function (value) {
    onChange(new RegExp(value, 'i'));
  }, 400), []);
  return __jsx(_Tooltip, {
    title: "To search, insert project name, project ID or analysis ID here",
    placement: "right"
  }, __jsx(_Input, {
    placeholder: "Filter by project name, project ID or analysis ID",
    onChange: function onChange(e) {
      return debouncedSetFilterParam(e.target.value);
    }
  }));
};
export default ProjectSearchBox;