import "antd/lib/modal/style";
import _Modal from "antd/lib/modal";
import "antd/lib/space/style";
import _Space from "antd/lib/space";
import "antd/lib/form/style";
import _Form from "antd/lib/form";
import "antd/lib/button/style";
import _Button from "antd/lib/button";
import "antd/lib/input/style";
import _Input from "antd/lib/input";
import "antd/lib/typography/style";
import _Typography from "antd/lib/typography";
var __jsx = React.createElement;
import "core-js/modules/es6.set.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.array.iterator.js";
import "core-js/modules/web.dom.iterable.js";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.function.name.js";
import "core-js/modules/es6.array.filter.js";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { createExperiment } from 'redux/actions/experiments';
import validateInputs, { rules } from 'utils/validateInputs';
import integrationTestConstants from 'utils/integrationTestConstants';
var Text = _Typography.Text,
  Title = _Typography.Title,
  Paragraph = _Typography.Paragraph;
var TextArea = _Input.TextArea;
var NewProjectModal = function NewProjectModal(props) {
  var onCreate = props.onCreate,
    onCancel = props.onCancel;
  var experiments = useSelector(function (state) {
    return state.experiments;
  });
  var _experiments$meta = experiments.meta,
    saving = _experiments$meta.saving,
    error = _experiments$meta.error;
  var dispatch = useDispatch();
  var _useState = useState(new Set()),
    projectNames = _useState[0],
    setProjectNames = _useState[1];
  var _useState2 = useState(''),
    projectName = _useState2[0],
    setProjectName = _useState2[1];
  var _useState3 = useState(''),
    projectDescription = _useState3[0],
    setProjectDescription = _useState3[1];
  var _useState4 = useState(false),
    isValidName = _useState4[0],
    setIsValidName = _useState4[1];
  var firstTimeFlow = experiments.ids.length === 0;
  var validationChecks = [rules.MIN_8_CHARS, rules.MIN_2_SEQUENTIAL_CHARS, rules.ALPHANUM_DASH_SPACE, rules.UNIQUE_NAME_CASE_INSENSITIVE];
  var validationParams = {
    existingNames: projectNames
  };
  useEffect(function () {
    setProjectNames(new Set(experiments.ids.map(function (id) {
      return experiments[id].name.trim();
    })));
  }, [experiments.ids]);
  useEffect(function () {
    setIsValidName(validateInputs(projectName, validationChecks, validationParams).isValid);
  }, [projectName, projectNames]);
  var submit = function submit() {
    setProjectName('');
    dispatch(createExperiment(projectName, projectDescription));
    onCreate(projectName, projectDescription);
  };
  return __jsx(_Modal, {
    className: integrationTestConstants.classes.NEW_PROJECT_MODAL,
    title: "Create a new project",
    open: true,
    footer: __jsx(_Button, {
      "data-test-id": integrationTestConstants.ids.CONFIRM_CREATE_NEW_PROJECT,
      type: "primary",
      key: "create",
      block: true,
      disabled: !isValidName,
      onClick: function onClick() {
        submit();
      }
    }, "Create Project"),
    onCancel: onCancel
  }, __jsx(_Space, null, __jsx(_Space, {
    direction: "vertical",
    style: firstTimeFlow ? {
      marginTop: '2rem'
    } : {}
  }, firstTimeFlow && __jsx(Title, {
    level: 3,
    style: {
      textAlign: 'center'
    }
  }, "Create a project to start analyzing your data in Cellenics"), __jsx(Paragraph, null, "Projects are where you can organize your data into samples, assign metadata, and start your analysis in Cellenics. Name it after the experiment you're working on."), __jsx(_Form, {
    layout: "vertical"
  }, __jsx(_Form.Item, {
    validateStatus: isValidName ? 'success' : 'error',
    help: __jsx("ul", null, validateInputs(projectName, validationChecks, validationParams).results.filter(function (msg) {
      return msg !== true;
    }).map(function (msg) {
      return __jsx("li", null, msg);
    })),
    label: __jsx("span", null, "Project name", ' ', __jsx(Text, {
      type: "secondary"
    }, "(You can change this later)")),
    required: true,
    name: "requiredMark"
  }, __jsx(_Input, {
    "data-test-id": integrationTestConstants.ids.PROJECT_NAME,
    "aria-label": "new project name",
    onChange: function onChange(e) {
      setProjectName(e.target.value.trim());
    },
    onKeyDown: function onKeyDown(e) {
      if (e.key === 'Enter' && isValidName) {
        submit();
      }
    },
    placeholder: "Ex.: Lung gamma delta T cells",
    value: projectName,
    disabled: saving
  })), __jsx(_Form.Item, {
    label: "Project description"
  }, __jsx(TextArea, {
    "data-test-id": integrationTestConstants.ids.PROJECT_DESCRIPTION,
    onChange: function onChange(e) {
      setProjectDescription(e.target.value);
    },
    placeholder: "Type description",
    autoSize: {
      minRows: 3,
      maxRows: 5
    },
    disabled: saving,
    "aria-label": "new project description"
  }))), saving && __jsx("center", null, __jsx(_Space, {
    direction: "vertical"
  }, __jsx(ClipLoader, {
    size: 50,
    color: "#8f0b10"
  }), __jsx(Text, null, "Creating project..."))), error && __jsx(Text, {
    type: "danger",
    style: {
      fontSize: 14
    }
  }, error))));
};
export default NewProjectModal;