import "antd/lib/button/style";
import _Button from "antd/lib/button";
var __jsx = React.createElement;
import React, { useState, useEffect, useRef } from 'react';
import { EditOutlined } from '@ant-design/icons';
var EditableParagraph = function EditableParagraph(props) {
  var onUpdate = props.onUpdate,
    value = props.value;
  var paragraphEditor = useRef();
  var _useState = useState(value),
    text = _useState[0],
    setText = _useState[1];
  var _useState2 = useState(false),
    isExpanded = _useState2[0],
    setIsExpanded = _useState2[1];
  var _useState3 = useState(false),
    isEditing = _useState3[0],
    setIsEditing = _useState3[1];
  useEffect(function () {
    if (isEditing) {
      paragraphEditor.current.focus();
    }
  }, [isEditing]);
  useEffect(function () {
    setText(value);
  }, [value]);
  var handleUpdate = function handleUpdate(e) {
    var content = e.target.textContent;
    setText(content);
    onUpdate(content);
    setIsEditing(false);
  };
  var renderEditor = function renderEditor() {
    return (
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
      __jsx("p", {
        contentEditable: true,
        ref: paragraphEditor,
        style: {
          backgroundColor: 'white'
        },
        onBlur: function onBlur(e) {
          return handleUpdate(e);
        },
        onKeyDown: function onKeyDown(e) {
          if (e.keyCode === 13) {
            handleUpdate(e);
          }
        },
        suppressContentEditableWarning: true
      }, text)
    );
  };
  var renderEditButton = function renderEditButton() {
    return __jsx(_Button, {
      style: {
        padding: 0
      },
      type: "link",
      icon: __jsx(EditOutlined, null),
      onClick: function onClick() {
        return setIsEditing(true);
      }
    });
  };
  var renderEllipsisLink = function renderEllipsisLink() {
    return __jsx(_Button, {
      type: "link",
      style: {
        padding: 0
      },
      onClick: function onClick() {
        return setIsExpanded(!isExpanded);
      }
    }, isExpanded ? 'less' : 'more');
  };
  var renderControls = function renderControls() {
    return __jsx(React.Fragment, null, renderEditButton(), text.length ? renderEllipsisLink() : __jsx(React.Fragment, null));
  };
  var renderContent = function renderContent() {
    if (isExpanded) {
      return __jsx("p", null, text, renderControls());
    }
    return __jsx("div", {
      style: {
        display: 'flex'
      }
    }, __jsx("div", {
      style: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        paddingTop: '0.25em'
      }
    }, text), renderControls());
  };
  if (isEditing) return renderEditor();
  return renderContent();
};
export default EditableParagraph;