import "antd/lib/space/style";
import _Space from "antd/lib/space";
import "antd/lib/button/style";
import _Button from "antd/lib/button";
var __jsx = React.createElement;
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import integrationTestConstants from 'utils/integrationTestConstants';
import processSampleUpload from 'utils/upload/processSampleUpload';
import DownloadDataButton from 'components/data-management/DownloadDataButton';
import LaunchAnalysisButton from 'components/data-management/LaunchAnalysisButton';
import FileUploadModal from 'components/data-management/FileUploadModal';
import ShareExperimentModal from 'components/data-management/ShareExperimentModal';
var ProjectMenu = function ProjectMenu() {
  var _samples$activeExperi;
  var dispatch = useDispatch();
  var samples = useSelector(function (state) {
    return state.samples;
  });
  var activeExperimentId = useSelector(function (state) {
    return state.experiments.meta.activeExperimentId;
  });
  var activeExperiment = useSelector(function (state) {
    return state.experiments[activeExperimentId];
  });
  var isSubsetted = activeExperiment === null || activeExperiment === void 0 ? void 0 : activeExperiment.isSubsetted;
  var _useState = useState(false),
    uploadModalVisible = _useState[0],
    setUploadModalVisible = _useState[1];
  var _useState2 = useState(false),
    shareExperimentModalVisible = _useState2[0],
    setShareExperimentModalVisible = _useState2[1];
  var selectedTech = (_samples$activeExperi = samples[activeExperiment === null || activeExperiment === void 0 ? void 0 : activeExperiment.sampleIds[0]]) === null || _samples$activeExperi === void 0 ? void 0 : _samples$activeExperi.type;
  var uploadFiles = function uploadFiles(filesList, sampleType) {
    processSampleUpload(filesList, sampleType, samples, activeExperimentId, dispatch);
    setUploadModalVisible(false);
  };
  return __jsx(React.Fragment, null, __jsx(_Space, null, __jsx(_Button, {
    "data-test-id": integrationTestConstants.ids.ADD_SAMPLES_BUTTON,
    onClick: function onClick() {
      return setUploadModalVisible(true);
    },
    disabled: isSubsetted
  }, "Add data"), __jsx(DownloadDataButton, null), __jsx(_Button, {
    onClick: function onClick() {
      return setShareExperimentModalVisible(!shareExperimentModalVisible);
    }
  }, "Share"), shareExperimentModalVisible && __jsx(ShareExperimentModal, {
    onCancel: function onCancel() {
      return setShareExperimentModalVisible(false);
    },
    experiment: activeExperiment
  }), __jsx(LaunchAnalysisButton, null)), uploadModalVisible ? __jsx(FileUploadModal, {
    onUpload: uploadFiles,
    currentSelectedTech: selectedTech,
    onCancel: function onCancel() {
      return setUploadModalVisible(false);
    }
  }) : __jsx(React.Fragment, null));
};
export default ProjectMenu;